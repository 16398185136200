export const ChargeTypeMaintenanceRequiredFieldList: string[] = [ 
    'ChargeType',  
]; 
export const ChargeTypeMaintenanceConstant = {
    SCREEN_CATEGORY: "CODE",     
    TITLE: "Charge Type", 
    CHARGE_TYPE: "Charge Type", 
    SUB_CHARGE_TYPE: 'Sub Charge Type', 
    DESC: 'Charge Type Desc.', 
    TPUT_IND: 'Throughput Statistic Ind.', 
    COUNT_FSP_IND: 'Count FSP Ind.', 
    CATEGORY: 'Type', 
    ACTIVE_IND: 'Active Ind.', 
} 
