import { ChargeTypeMaintenanceConstant } from "presentation/constant/ChargeType/ChargeTypeMaintenanceConstant";
import { useChargeTypeMaintenanceVM } from "presentation/hook/ChargeType/useChargeTypeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const ChargeTypeEditPanelTitleBar: React.FC = () => { 
    const [chargeTypeState] = useChargeTypeMaintenanceTracked(); 
    const chargeTypeVM = useChargeTypeMaintenanceVM(); 
    const messageBarVM = useMessageBarVM(); 
    const {currentSelectedRow, masterState} = chargeTypeState; 
    const {editingEntity, isAdd, isEditable, isRead} = masterState; 

    const [isLoading, setIsLoading] = useState(false); 
    const currentEntity = isRead? currentSelectedRow : editingEntity; 
    const handleReset = useCallback(async () => { 
        chargeTypeVM.onReset(); 
    }, [chargeTypeVM]); 
    const handleEdit = useCallback(()=>{ 
        chargeTypeVM.onEditClick(currentEntity); 
    },[currentEntity, chargeTypeVM]) 
    const handleSave = useCallback(async () => { 
        if(!currentEntity.chargeCategory){
            messageBarVM.showError("Charge Category is mandatory.");
            return;
        }
        setIsLoading(true); 
        try { 
            chargeTypeVM.onSaveClicked(); 
            const res = await chargeTypeVM.onSave(currentEntity,isAdd); 
            if(!!!res || !res.success){
                messageBarVM.showWarining(res?.data??'Save data failed.'); 
            }else { 
                chargeTypeVM.onClose(); 
                setIsLoading(true); 
            } 
        } catch (error) { 
            messageBarVM.showError('Save data failed.');
        }finally{ 
            setIsLoading(false) 
        } 

    },[currentEntity, isAdd, messageBarVM, chargeTypeVM]); 
    return <Sidebarheader style={{width: '100%'}}> 
        {isLoading && <Loader Indicator="Spinner" size="Medium" />} 
        <Breadcrumb> 
            <HPHBreadcrumb breadcrumbData={[{title: ChargeTypeMaintenanceConstant.SCREEN_CATEGORY}, 
                { title:  ChargeTypeMaintenanceConstant.TITLE }]}onCurrentClick={()=>{}}> 
            </HPHBreadcrumb> 
        </Breadcrumb> 
        <StyledAction> 
            {!(isAdd || isEditable) && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} /> }
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={chargeTypeVM.onClose} /> }
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} /> }
        </StyledAction> 
    </Sidebarheader> 
} 
export default memo(ChargeTypeEditPanelTitleBar); 
