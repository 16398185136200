import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeTypeMaintenance from "./ChargeTypeMaintenance";
import { ChargeTypeMaintenanceFormPanel } from "./ChargeTypeMaintenanceFormPanel";
export const ChargeTypeMasterView: React.FC = () => { 
    const [chargeTypeState] = useChargeTypeMaintenanceTracked(); 
    const { isShowEditPanel } = chargeTypeState;
    return <> 
        <div className="main-comp-wrapper im-hide-side-form-draggable"> 
            <ChargeTypeMaintenance/> 
            { 
                (isShowEditPanel &&  
                <SliderPanel 
                    isOpen={false} 
                    leftSectionWidth={"100%"} 
                    rightSectionWidth={"0%"} 
                    leftChildren={<ChargeTypeMaintenanceFormPanel/>} 
                /> 
                ) 
            } 
        </div> 
    </> 
} 
