import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl"; 
import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider"; 
import { ChargeTypeMaintenanceVM } from "presentation/viewModel/ChargeType/ChargeTypeMaintenanceVM"; 
import { useMemo } from "react"; 
export const useChargeTypeMaintenanceVM = () => { 
    const [, setChargeTypeMaintenanceState] = useChargeTypeMaintenanceTracked(); 
    const ChargeTypeMainVM = useMemo(() => 
        ChargeTypeMaintenanceVM({ 
            dispatch: [setChargeTypeMaintenanceState], 
            repo: ChargeTypeRepoImpl(), 
        }), [setChargeTypeMaintenanceState]) 
    return ChargeTypeMainVM 
} 
