import * as yup from 'yup';
export const commonChargeTypeMaintenanceValidation: { [x: string]: any; } = { 
  chargeType: yup.string().required("Missing"), 
  // subChargeType: yup.string().required("Missing"), 
  chargeCategory: yup.string().required("Missing"), 
}; 
export const createChargeTypeMaintenanceValidation: { [x: string]: any; } = { 
  ...commonChargeTypeMaintenanceValidation, 
}; 
export const createChargeTypeMaintenanceValidationSchema = yup.object().shape(createChargeTypeMaintenanceValidation); 
