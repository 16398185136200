export interface ChargeTypeEntity {
    key: string,
    chargeType: string,
    versionIdentifier: {
        version?: string
    },
    subChargeType: string | null,
    chargeTypeDesc: string | null,
    tputStatisticInd?: "Y" | "N",
    countFspInd?: "Y" | "N",
    chargeCategory: string | null,
    activeInd: "Y" | "N",
    subChargeTypeSpecMark?: string | null,
    detailPrintInd?: string | null,

    [key: string]: string | boolean | null | Object | undefined
}

export const EMPTY_CHARGE_TYPE_ENTITY : ChargeTypeEntity = {
    key: "",
    chargeType: "",
    versionIdentifier: {},
    subChargeType: null,
    chargeTypeDesc: null,
    tputStatisticInd: "Y",
    countFspInd: "Y",
    chargeCategory: "GEN",
    activeInd: "Y",
    subChargeTypeSpecMark: null,
    detailPrintInd: null,
}