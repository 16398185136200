import { Permission } from "presentation/constant/ANAInfo/PermissionName"; 
import { ChargeTypeMaintenanceProvider } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider"; 
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper"; 
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper"; 
import { ChargeTypeMasterView } from "presentation/view/section/ChargeType/ChargeTypeMasterView"; 
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled"; 
const ChargeTypeMaintenanceCont = () => { 
    return <ANAInfoWrapper permission={Permission.CHARGE_TYPE_MAINTENANCE}> 
        <MessageBarWrapper> 
            <ChargeTypeMaintenanceProvider> 
                <GridStyles/> 
                <ChargeTypeMasterView/> 
            </ChargeTypeMaintenanceProvider> 
        </MessageBarWrapper> 
    </ANAInfoWrapper> 
} 
export default ChargeTypeMaintenanceCont; 
