import { ChargeTypeEntity, EMPTY_CHARGE_TYPE_ENTITY } from "domain/entity/ChargeType/ChargeTypeEntity"; 
import { BaseViewChangeSate } from "../BaseViewChangeState"; 
import { DropdownProps } from "../DropdownProps"; 
export interface TariffCodeDropdownOptions { 
    ChargeTypeTypeDropdownOptions: DropdownProps[], 
} 
export interface MasterChangeState extends BaseViewChangeSate { 
    editingEntity: ChargeTypeEntity, 
} 
export interface ChargeTypeMaintenanceModel { 
    isLoading: boolean, 
    tableData: ChargeTypeEntity[], 
    currentSelectedRow: ChargeTypeEntity, 
    selectedRows: ChargeTypeEntity[], 
    dynamicOptions: TariffCodeDropdownOptions, 
    masterState: MasterChangeState, 
    isBackMaster: boolean, 
    isShowEditPanel: boolean,
} 
export const EMPTY_MASTER_CHARGE_TYPEANY_MODEL: ChargeTypeMaintenanceModel = { 
    isLoading: false, 
    tableData: [], 
    currentSelectedRow: { ...EMPTY_CHARGE_TYPE_ENTITY }, 
    selectedRows: [], 
    dynamicOptions: { 
        ChargeTypeTypeDropdownOptions: [] 
    }, 
    masterState: { 
        isSliderOpen: false, 
        isTabularDataActive: false, 
        isAdd: false, 
        isRead: false, 
        isEditable: false, 
        isSaveClicked: false, 
        lastEditRowId: "", 
        isSelected: false, 
        slider: { 
            isAdd: false, 
            isRead: false, 
            isEditable: false 
        }, 
        editingEntity: EMPTY_CHARGE_TYPE_ENTITY, 
    }, 
    isBackMaster: false, 
    isShowEditPanel: false,
} 
